export const slugify = (string: string) => {
  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};

export const extractFirstSentence = (text: string) => {
  return text.split(/([.!?])/).reduce((acc, part, index, array) => {
    if (index === 0 && array[index + 1]) {
      acc = part + array[index + 1];
    }
    return acc;
  }, '');
};

export const validateName = (value: string, error: string) => {
  if (!value) return ' ';

  if (value.length < 2 || !/^[A-Za-zÀ-ÖØ-öø-ÿ ]+$/.test(value)) return error;
  return '';
};

export const validatePhoneNumber = (value: string, error: string) => {
  if (!value) return ' ';

  if (!/^\+?\d+$/.test(value)) return error;
  return '';
};

export const validateEmail = (value: string, error: string) => {
  if (!value) return ' ';

  if (!/\S+@\S+\.\S+/.test(value)) return error;
  return '';
};

export const processJobContent = (content: string) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = content;

  const removeEmptyElements = (node: Node) => {
    const children = Array.from(node.childNodes);
    children.forEach((child) => {
      if (child.nodeType === Node.ELEMENT_NODE) {
        const element = child as Element;

        if (element.tagName === 'H2') return;

        removeEmptyElements(element);

        if (!element.textContent?.trim() && element.children.length === 0)
          element.remove();
      }
    });
  };

  removeEmptyElements(tempDiv);

  const paragraphs = tempDiv.querySelectorAll('p');

  paragraphs.forEach((p) => {
    const strongTag = p.querySelector('strong');
    if (strongTag) {
      const textContent = strongTag.textContent
        ? strongTag.textContent.trim()
        : '';
      if (textContent) {
        const h2 = document.createElement('h2');
        h2.textContent = textContent;

        if (p.parentNode) p.parentNode.replaceChild(h2, p);
      }
    }
  });

  return tempDiv.innerHTML;
};

export const generateHrefLangUrl = (locale: string, path: string) => {
  const baseUrl = 'https://www.consulteer.com';
  if (locale === 'en-US') return `${baseUrl}${path}`;
  return `${baseUrl}/${locale}${path}`;
};
